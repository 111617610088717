<template>
  <div class="columns is-mobile is-multiline">
    <!-- Total Uptime -->
    <div class="column is-12-mobile is-4-tablet is-6-desktop is-4-widescreen">
      <div class="box has-padding-100 has-background-info has-text-white">
        <p class="is-size-5">
          <strong>{{ totalUptime }} Total Uptime</strong>
        </p>
        <p class="is-size-7">Since {{ inception }}</p>
      </div>
    </div>
    <!-- Interval Uptime -->
    <div class="column is-12-mobile is-4-tablet is-6-desktop is-4-widescreen">
      <div class="box has-padding-100 has-background-success has-text-white">
        <p class="is-size-5">
          <strong>{{ intervalUptime }} Uptime</strong>
        </p>
        <p class="is-size-7">In the last {{ interval }}</p>
      </div>
    </div>
    <!-- Interval Downtime -->
    <div class="column is-12-mobile is-4-tablet is-6-desktop is-4-widescreen">
      <div class="box has-padding-100 has-background-danger has-text-white">
        <p class="is-size-5">
          <strong>{{ intervalDowntime }} Downtime</strong>
        </p>
        <p class="is-size-7">In the last {{ interval }}</p>
      </div>
    </div>
    <!-- Current status -->
    <div class="column is-12-mobile is-4-tablet is-6-desktop is-4-widescreen">
      <div class="box has-padding-100">
        <p class="is-size-5">Current status</p>
        <p class="is-size-7" :class="statusClass">
          <strong>{{ currentStatus }}</strong>
        </p>
      </div>
    </div>
    <!-- Average response time -->
    <div class="column is-12-mobile is-4-tablet is-6-desktop is-4-widescreen">
      <div class="box has-padding-100">
        <p class="is-size-5">Average response time</p>
        <p class="is-size-7">
          <strong>{{ avgResponseTime }}</strong>
        </p>
      </div>
    </div>
    <!-- Last response time -->
    <div class="column is-12-mobile is-4-tablet is-6-desktop is-4-widescreen">
      <div class="box has-padding-100">
        <p class="is-size-5">Last response time</p>
        <p class="is-size-7">
          <strong>{{ lastResponseTime }}</strong>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MonitoringStats",
  props: {
    monitor: {
      type: Object,
      default() {
        return null;
      }
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    logs() {
      return this.$_.get(this.monitor, "logs", []);
    },
    responseTimes() {
      return this.$_.get(this.monitor, "response_times", []);
    },
    inception() {
      const datetime = this.$_.get(this.$_.last(this.logs), "datetime");
      if (!datetime) return "…";
      return this.$moment.unix(datetime).format("MMMM Do, YYYY");
    },
    totalUptime() {
      const uptime = this.$_.get(this.monitor, "all_time_uptime_ratio");
      return uptime ? `${this.$_.round(parseFloat(uptime), 2)}%` : `…`;
    },
    interval() {
      const datetime = this.$_.get(this.oldestResponse, "datetime");
      if (!datetime) return "…";
      return this.$moment
        .unix(datetime)
        .fromNow("d")
        .replace(/^((an|a)\s)/gi, "");
    },
    intervalUptime() {
      const uptime = this.$_.get(this.monitor, "custom_uptime_ratio");
      return uptime ? `${this.$_.round(parseFloat(uptime), 2)}%` : `…`;
    },
    intervalDowntime() {
      const uptime = this.$_.get(this.monitor, "custom_uptime_ratio");
      return uptime ? `${this.$_.round(100 - parseFloat(uptime), 2)}%` : `…`;
    },
    lastLog() {
      return this.$_(this.logs)
        .filter(
          l => parseInt(l.reason.code) >= 200 && parseInt(l.reason.code) < 600
        )
        .map(log => {
          const code = parseInt(log.reason.code);
          return this.$_.merge({}, log, {
            reason: {
              code,
              class:
                code >= 200 && code < 400
                  ? `has-text-success`
                  : `has-text-danger`
            }
          });
        })
        .first();
    },
    currentStatus() {
      return this.lastLog
        ? `${this.lastLog.reason.code} ${this.lastLog.reason.detail}`
        : "…";
    },
    statusClass() {
      return this.$_.get(this.lastLog, "reason.class", "has-text-info");
    },
    avgResponseTime() {
      const avgResponse = this.$_.get(
        this.monitor,
        "average_response_time",
        null
      );
      return avgResponse ? `${avgResponse}ms` : "…";
    },
    oldestResponse() {
      return this.$_(this.responseTimes).last();
    },
    newestResponse() {
      return this.$_(this.responseTimes).first();
    },
    lastResponseTime() {
      return this.newestResponse ? `${this.newestResponse.value}ms` : "…";
    }
  }
};
</script>
